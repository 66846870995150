// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aspens-js": () => import("/opt/build/repo/src/pages/aspens.js" /* webpackChunkName: "component---src-pages-aspens-js" */),
  "component---src-pages-colorado-flag-js": () => import("/opt/build/repo/src/pages/colorado-flag.js" /* webpackChunkName: "component---src-pages-colorado-flag-js" */),
  "component---src-pages-commissions-js": () => import("/opt/build/repo/src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-contact-the-artist-js": () => import("/opt/build/repo/src/pages/contact-the-artist.js" /* webpackChunkName: "component---src-pages-contact-the-artist-js" */),
  "component---src-pages-exhibitions-js": () => import("/opt/build/repo/src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-fine-art-prints-js": () => import("/opt/build/repo/src/pages/fine-art-prints.js" /* webpackChunkName: "component---src-pages-fine-art-prints-js" */),
  "component---src-pages-her-studio-js": () => import("/opt/build/repo/src/pages/her-studio.js" /* webpackChunkName: "component---src-pages-her-studio-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-original-artwork-js": () => import("/opt/build/repo/src/pages/original-artwork.js" /* webpackChunkName: "component---src-pages-original-artwork-js" */),
  "component---src-pages-other-artwork-js": () => import("/opt/build/repo/src/pages/other-artwork.js" /* webpackChunkName: "component---src-pages-other-artwork-js" */)
}

